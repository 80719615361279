

h1 {
	left: 0;
	right: 0;
	top: 0;
	position: relative;
	font-size: 30;
	color: black;
	margin-top: 40px;
	margin-bottom: 40px;
	z-index: -100;
}

.container {
	position: absolute;
	left: 0;
	right: 0;
	top: 80px;
	bottom: 0;
	margin-top: 0px;
	margin-left: 40px;
	width: calc(100% - 80px);
	max-width: 100vw;
	overflow-x: auto;
	padding: 0 10px;
	height: calc(100vh - 100px);
	-ms-overflow-style: none;  /* Internet Explorer 10+ */
	scrollbar-width: none;  /* Firefox */
}

#root {
	overflow: auto; /* or overflow: scroll; */
}
  
.text-black {
	color: #071437 !important;
}
  
