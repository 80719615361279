
.hidden {
	width: 0;
	height: 0;
	z-index: -1000;
	opacity: 0;
	position: fixed;
	display: none;
}

:root {
	--color-light-gray: #f0f0f0;
	--color-dark: #FB5B86;
	--color-white: #fff;
	--color-gray: #e0e0e0;
  }
  
  .mateza-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10000;
	background: var(--color-light-gray);
  }
  
  .mateza-container .left-section {
	background-color: rgb(23, 27, 62);
	color: var(--color-dark);
	position: relative;
	overflow: hidden;
  }

  .mateza-container h1 {
	font-size: 37px;
	margin: 0;
	margin-bottom: 10px;
	margin-top: 10px;
  }

  .mateza-container .left-section p {
	font-size: 17px;
  }

  .mateza-container .left-panel-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
	opacity: 0.1;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
  }
  
  .mateza-container .left-section .logo,
  .mateza-container .left-section h1,
  .mateza-container .left-section p {
	z-index: 2;
	position: relative;
  }
  
  .mateza-container .left-section .logo {
	transform: translateX(50%);
  }
  
  .mateza-container .left-section h1,
  .mateza-container .left-section p {
	color: var(--color-dark);
  }
  
  .mateza-container .right-section {
	background-color: var(--color-light-gray);
  }
  
  .mateza-container .right-section .sign-in-form {
	background: rgba(255, 255, 255, 0.85);
	padding: 2rem;
	border-radius: 8px;
  }
  
  .mateza-container .right-section h2,
  .mateza-container .right-section label,
  .mateza-container .right-section a {
	color: var(--color-dark);
	font-weight: 500;
  }
  
  .mateza-container .right-section .btn-primary {
	background-color: #b3a4a5;
	border-color: var(--color-white);
	color: white;
	height: 50px;
	width: 100%;
	z-index: 10000;
  }

  .mateza-container input {
	margin-top: 5px;
	margin-bottom: 20px;
  }
  
  .mateza-container .right-section .btn-primary:hover {
	background-color: var(--color-gray);
	border-color: var(--color-gray);
  }
  
  .mateza-container .form-control {
	height: 45px;
  }
  
  .mateza-container .logo {
	top: 20px;
	right: 30px;
	width: 50px;
  }
  
  @media (max-width: 767px) {
	.mateza-container .left-section {
	  display: none;
	}
  
	.mateza-container .logo {
	  display: none;
	}
  
	.mateza-container .sign-in-form {
	  width: 90% !important;
	}
  }
  