button {
	border: 0;
  }
  
  .ex-table {
	position: relative;
	z-index: 1;
	border-collapse: separate;
	width: 100%;
	font-size: 0.9375rem;
  }
  
  .ex-table__btn {
	background-color: hsl(240, 4%, 95%);
  }
  
  .ex-table__btn:active {
	background-color: hsl(240, 4%, 90%);
  }
  
  .ex-table__btn-icon {
	position: relative;
	display: block;
	margin: 0 auto;
  }
  
  .ex-table__btn-icon::before, .ex-table__btn-icon::after {
	content: "";
	position: absolute;
	width: 2px;
	height: 80%;
	border-radius: 5em;
	background: currentColor;
	top: 0;
	transform-origin: 50% 100%;
	will-change: transform;
	transition: transform 0.2s;
  }
  
  .ex-table__btn-icon::before {
	left: 50%;
	transform: rotate(-45deg);
  }
  
  .ex-table__btn-icon::after {
	left: calc(50% - 1px);
	transform: rotate(45deg);
  }
  
  .ex-table__more-content {
	flex-direction: column;
	gap: 1rem;
	line-height: 1.58;
	display: none;
  }
  
  .ex-table__row--show-more-content .ex-table__more-content {
	display: flex;
  }
  
  .ex-table__row--show-more-content .ex-table__btn-icon::before {
	transform: translateY(-80%) rotate(-135deg);
  }
  
  .ex-table__row--show-more-content .ex-table__btn-icon::after {
	transform: translateY(-80%) rotate(135deg);
  }
  
  @media not all and (min-width: 42.5rem) {
	.ex-table {
	  border-spacing: 0 1.5rem;
	  margin-top: -3rem;
	}
  
	.ex-table__header {
	  position: absolute;
	  -webkit-clip-path: inset(50%);
		  clip-path: inset(50%);
	}
  
	.ex-table__cell {
	  display: block;
	  padding: 1rem;
	  width: 100%;
	  text-align: left;
	  border: 1px solid hsl(240, 4%, 85%);
	  border-bottom-width: 0;
	}
  
	.ex-table__row .ex-table__cell:last-child {
	  border-bottom-width: 1px;
	}
  
	.ex-table__label {
	  display: block;
	  font-weight: bold;
	  color: hsl(230, 13%, 9%);
	  margin-bottom: 0.25rem;
	}
  
	.ex-table__title {
	  font-weight: bold;
	  color: hsl(230, 13%, 9%);
	  margin-bottom: 0.5rem;
	  display: block;
	}
  
	.ex-table__btn {
	  border-radius: 0.375em;
	  padding: 0.75rem;
	  width: 100%;
	}
  
	.ex-table__btn-icon {
	  width: 1em;
	  height: 1em;
	}
  
	.ex-table__more-content {
	  padding: 1.5rem 0 0;
	}
  }
  
  @media (min-width: 42.5rem) {
	.ex-table__header .ex-table__cell {
	  font-weight: bold;
	  color: hsl(230, 13%, 9%);
	  text-align: left;
	}

	.hidden_desktop {
		display: none;
	}
	
	.ex-table__cell {
	  padding: 0.75rem 1rem 0.75rem 0;
	  border-bottom: 1px solid hsl(240, 4%, 85%);
	}
  
	.ex-table__row .ex-table__cell:last-child {
	  padding-right: 0;
	}
  
	.ex-table__label {
	  display: none;
	}
  
	.ex-table__title {
	  display: none;
	}
  
	.ex-table__btn {
	  width: 40px;
	  height: 40px;
	  border-radius: 50%;
	  float: right;
	}
  
	.ex-table__btn-icon {
	  width: 16px;
	  height: 16px;
	}
  
	.ex-table__more-content {
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  background-color: hsl(0, 0%, 100%);
	  padding: 1.5rem 0;
	  border-bottom: 1px solid hsl(240, 4%, 85%);
	}
	
	.ex-table__row--show-more-content .ex-table__cell {
	  border-bottom-color: hsl(0, 0%, 100%);
	}
  }
  
  
.search-bar-day {
	padding-top: 0px;
	padding-bottom: 40px;
	margin-top: -8px;
	border-bottom: 1px solid #ccc;
  }
  
  .search-bar-day input {
	width: 100%;
	padding: 10px;
	border-radius: 5px;
	border: 1px solid #ccc;
  }

  /* general.css or day.css */
  .print-buttons {
	margin-top: 30px;
  }

  .print-only {
	display: none;
  }

  /* Add this CSS for print padding */
@media print {
	.ex-table {
	  padding-left: 5rem;
	  padding-right: 5rem;
	}

	.print-only {
		display: block;
		padding-top: 5rem;
		padding-left: 5rem;
	  	padding-right: 5rem;
	}
  }


  .header-container {
    display: flex;
    align-items: center;
  }
  .header-container h1 {
    margin-right: 10px; /* Adjust as needed */
  }

  .ex-table__row {
	transition: transform 0.2s ease-in-out;
  }
  
  .ex-table__row:hover {
	transform: scale(1.03);
  }